import React from 'react';
import whypc from '../../../components/model/whypc'
import '../../../assets/whypc/css/base.css';
import '../../../assets/whypc/css/index.css';
import '../../../assets/whypc/css/video.css';
//import {Redirect}from 'react-router-dom';
//import Api from '../../model/Api';
//import Axios from 'axios'
import { Layout, Menu} from 'antd';
import {HashRouter as Router, Route, Link, Redirect} from 'react-router-dom';
import Logo from "../../../assets/whypc/image/logo.png";
import  backmp4 from "../../../assets/whypc/image/backmp4.mp4";
import  video from "../../../assets/whypc/image/news1.mp4";
import  video2 from "../../../assets/whypc/image/news2.mp4";
import  video3 from "../../../assets/whypc/image/news3.mp4"
import  video4 from "../../../assets/whypc/image/news4.mp4";
import  homeimg from "../../../assets/whypc/image/home.png";
import  zhibo from "../../../assets/whypc/image/zhibo.png";
import  zmdss from "../../../assets/whypc/image/zmdss.png";
import  huodongimg from "../../../assets/whypc/image/huodong.png";
import  my from "../../../assets/whypc/image/my.png";
import  homeimgafter from "../../../assets/whypc/image/homeafter.png";
import  zhiboafter from "../../../assets/whypc/image/zhiboafter.png";
import  zmdssafter from "../../../assets/whypc/image/zmdssafter.png";
import  huodongimgafter from "../../../assets/whypc/image/huodongafter.png";
import  myafter from "../../../assets/whypc/image/myafter.png";
import logoimg from "../../../assets/whypc/image/logoimg.png";
import android from "../../../assets/whypc/image/android.png";
import iosimg from "../../../assets/whypc/image/ios.png";
import xiaochengxu from "../../../assets/whypc/image/xiaochengxu.png";
import beian from "../../../assets/whypc/image/beian.png";
import  Videos from '../index/Video';
import  About from '../index/About';
import  Zhuanti from '../index/Zhuanti';
import  List from '../index/List';
import Xkz from "../index/Xkz";
//import  Reading from '../index/Reading';
import  Players from '../index/Players';
const { Header, Content,Footer } = Layout;

class Index extends React.Component{

    constructor(props) {

        super(props);
        this.state = {
            vstyle:"none",
            MenuList:[
                {
                    key: '/video',
                    label: '首页'
                },
                {
                    key: '/zhuanti',
                    label: '云专题'
                },
                {
                    key: '/about',
                    label: '关于我们'
                }
            ]
        }
    }
    componentDidMount(){
        localStorage.setItem("openKey",1);

       //  localStorage.setItem("seletKey",1);
       if(this.props.match.url==="/"){
         //window.location.href="https://www.hnbxwhy.com/#/video";
            //window.location.href="http://localhost:3000/#/video";
           this.props.history.replace("/video")
       }
       // console.log( localStorage.setItem("seletKey",1))
    }
    onSelect =(data) =>{
        let  key  =data;
         // em("openKey",key);
        localStorage.setItem("seletKey",key);
    }

    render(){
        const { current } = this.state;
        return(
            <Router>
            <Layout className="layout" >
                <Header className="header">
                    <div className="logo">
                    <div className="logoimg" >
                         <img className="logo_title" src={Logo} alt="logo"/>
                    </div>
                    </div>
                    <Menu  mode="horizontal"

                           defaultOpenKeys={[localStorage.getItem("openKey")?localStorage.getItem("openKey"):'']}
                           defaultSelectedKeys={[localStorage.getItem("seletKey")?localStorage.getItem("seletKey"):'']} className="menu">
                        <Menu.Item key="1">
                            <Link to={`/video`} onClick={(e)=>this.onSelect('1')}>首页</Link>
                        </Menu.Item>
                        <Menu.Item key="4">
                            <Link to={`/zhuanti`} onClick={(e)=>this.onSelect('4')}>云专题</Link>
                        </Menu.Item>
                        {/*<Menu.Item key="3">*/}
                        {/*  { /*<Link to={`/reading`} onClick={(e)=>this.onSelect('3')}>全民阅读大会</Link> *!/*/}
						{/*   <Link to={`/list`} onClick={(e)=>this.onSelect('3')}>视频列表</Link>*/}
                        {/*</Menu.Item>*/}
                        <Menu.Item key="2">
                            <Link to={`/about`} onClick={(e)=>this.onSelect('2')}>关于我们</Link>
                        </Menu.Item>


                    </Menu>
                </Header>
                <Content style={{ padding: '0 0' }}>
                    <Route exact path="/Video" component={Videos} />
                    {/*<Route exact path="/Reading" component={Reading} />*/}
                    <Route exact path="/About" component={About} />
					<Route exact path="/Zhuanti" component={Zhuanti} />
					<Route exact path="/List" component={List} />
                    <Route exact path="/Xkz" component={Xkz} />
                </Content>
            </Layout>
            </Router>
        )
    }

}

export default Index;

