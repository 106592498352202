import './App.css';
import PcIndex from "./components/whypc/index/Index";
import PcAbout from "./components/whypc/index/About";
import PcAVideo from "./components/whypc/index/Video";
import PcXkz from "./components/whypc/index/Xkz";
//import PcRead from "./components/whypc/index/Reading";
//import PcPlayers from "./components/whypc/index/Players";
import PcZhuanti from "./components/whypc/index/Zhuanti";
import Pcfooter from "./components/whypc/index/Footers";
import PcList from "./components/whypc/index/List";
// import Login from "./components/zmdss/login/Login";
// import Back from "./components/zmdss/leftList/Back";
// import Edit from './components/zmdss/leftList/Edit'
import React, { Component } from "react";
import {HashRouter as Router,Route,Link} from 'react-router-dom';
/** ant布局*/
import { Layout} from 'antd';
const { Header, Sider, Content } = Layout;

class App extends Component {

  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  render() {
    return (
      <Router>
        <div className="app">
             <Route exact path="/" component={PcIndex}/>
          <Route exact path="/about" component={PcIndex}/>
          <Route exact path="/video" component={PcIndex}/>
          <Route exact path="/players" component={PcIndex}/>
          <Route exact path="/footer" component={PcIndex}/>
		  <Route exact path="/zhuanti" component={PcIndex}/>
		  <Route exact path="/list" component={PcIndex}/>
          <Route exact path="/xkz" component={PcXkz}/>
        </div>
      </Router>
    );
  }
}

export default App;
