import React from 'react';
import '../../../assets/whypc/css/index.css';
import beian from "../../../assets/whypc/image/beian.png";
import {Layout} from "antd";
const { Footer } = Layout;
class Footers extends React.Component {
    componentDidMount(){

    }
    feedBack = ()=>{

    }
    render() {

        return (  <Footer className="bootom">
                <div className="feet">
                    <p>联系电话：4008809382 |< a href="/#">服务条款</ a> | < a
                        href="/#" onClick="feedBack()"> 意见反馈</ a></p>
                    <p>
                        <a href="http://video.hnbxwhy.com/9222976/wlwhjyxkz.png"> 网络文化经营许可证：豫网文[2024]1532-018号</a>
                        <a href="https://www.iyunshu.com/marketing.html?pageId=2307050005718383">
                            <p style={{float: "left"}}>增值电信业务经营许可证：豫B2-20130011 |
                                Copyright &copy; 2014-2024 www.hnbxwhy.com 文化云
                                版权所有，未经授权禁止复制或镜像</p></a>
                        <a href="https://beian.miit.gov.cn/#/Integrated/index">  <img src={beian} alt="百姓文化云备案"
                                                                                      style={{padding_left: '5px', padding_top: '4px'}}/></a>

                    </p>
                </div>

            </Footer>
        )
    }
}
export default Footers;
