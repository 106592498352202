import React from 'react';
import '../../../assets/whypc/css/base.css';
import '../../../assets/whypc/css/about.css';
import {Layout} from 'antd';
import { Link} from 'react-router-dom';
import beian from "../../../assets/whypc/image/beian.png";
const { Header, Content,Footer } = Layout;
class Xkz extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            seletKey:0,

        }
    }
    componentDidMount(){

    }

    dianji=(n)=> {
        let  statename="develop"+n+"after";
        this.handleChange("develop"+n,this.state.arrdev[statename]);
    }
    mouseout=(n)=>{
        let statename="develop"+n;
        this.handleChange("develop"+n,this.state.arrdev[statename]);
    }
    //修改state
    handleChange=(name,val)=> {
        this.setState({
            [name]: val
        })
    }
    feedBack = ()=>{
        console.log(11111)
    }
    render(){
        const contentStyle = {
            height: '160px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center',
            background: '#364d79',
        };
        return (

        <div className="aboutdiv">
                <div className="contact">
                    <div className="bgtitle">
                        <img src="http://video.hnbxwhy.com/9222976/zzdxyewjyxkz.png" alt="http://video.hnbxwhy.com/9222976/zzdxyewjyxkz.png"/><br/>
                        <img src="http://video.hnbxwhy.com/9222976/zzdxywjyxkz2.png" alt="http://video.hnbxwhy.com/9222976/zzdxywjyxkz2.png"/>
                    </div>
                </div>
            </div>
        )
    }

}

export default Xkz;
