import React from 'react';
import '../../../assets/whypc/css/base.css';
import '../../../assets/whypc/css/about.css';
import {Carousel, Layout} from 'antd';
import { Link} from 'react-router-dom';
import banner from "../../../assets/whypc/image/aboutbanner.png";
import about from "../../../assets/whypc/image/about.png";
import develop from "../../../assets/whypc/image/develop.png";
import contact from "../../../assets/whypc/image/contact.png";
import fzlcimg from "../../../assets/whypc/image/fzlc.png";
import develop1 from "../../../assets/whypc/image/develop1.png";
import develop2 from "../../../assets/whypc/image/develop2.png";
import develop3 from "../../../assets/whypc/image/develop3.png";
import develop4 from "../../../assets/whypc/image/develop4.png";
import develop5 from "../../../assets/whypc/image/develop5.png";
import develop6 from "../../../assets/whypc/image/develop6.png";
import develop7 from "../../../assets/whypc/image/develop7.png";
import develop8 from "../../../assets/whypc/image/develop8.png";
import develop9 from "../../../assets/whypc/image/develop9.png";
import develop1after from "../../../assets/whypc/image/develop1after.png";
import develop2after from "../../../assets/whypc/image/develop2after.png";
import develop3after from "../../../assets/whypc/image/develop3after.png";
import develop4after from "../../../assets/whypc/image/develop4after.png";
import develop5after from "../../../assets/whypc/image/develop5after.png";
import develop6after from "../../../assets/whypc/image/develop6after.png";
import develop7after from "../../../assets/whypc/image/develop7after.png";
import develop8after from "../../../assets/whypc/image/develop8after.png";
import develop9after from "../../../assets/whypc/image/develop9after.png";
import lxwm from "../../../assets/whypc/image/lxwm.png";
import bootom from "../../../assets/whypc/image/bottom.png";
import beian from "../../../assets/whypc/image/beian.png";
const { Header, Content,Footer } = Layout;
class About extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
                seletKey:2,
                arrdev:{
                    develop1:develop1,
                    develop2:develop2,
                    develop3:develop3,
                    develop4:develop4,
                    develop5:develop5,
                    develop6:develop6,
                    develop7:develop7,
                    develop8:develop8,
                    develop9:develop9,
                    develop1after:develop1after,
                    develop2after:develop2after,
                    develop3after:develop3after,
                    develop4after:develop4after,
                    develop5after:develop5after,
                    develop6after:develop6after,
                    develop7after:develop7after,
                    develop8after:develop8after,
                    develop9after:develop9after
                },
                develop1:develop1,
                develop2:develop2,
                develop3:develop3,
                develop4:develop4,
                develop5:develop5,
                develop6:develop6,
                develop7:develop7,
                develop8:develop8,
                develop9:develop9
        }
    }
    componentDidMount(){

    }

    dianji=(n)=> {
        let  statename="develop"+n+"after";
       this.handleChange("develop"+n,this.state.arrdev[statename]);
    }
    mouseout=(n)=>{
        let statename="develop"+n;
         this.handleChange("develop"+n,this.state.arrdev[statename]);
}
    //修改state
    handleChange=(name,val)=> {
        this.setState({
            [name]: val
        })
    }
    feedBack = ()=>{
        console.log(11111)
    }
    render(){
        const contentStyle = {
            height: '160px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center',
            background: '#364d79',
        };
        return(
            <div className="aboutdiv">
            <Carousel autoplay>
                <div>
                  <img src={banner} class="lunbo" alt="lunbo"/>
                </div>
                <div>
                    <img src={banner} class="lunbo" alt="lunbo"/>
                </div>
                <div>
                    <img src={banner} class="lunbo" alt="lunbo"/>
                </div>
                <div>
                    <img src={banner} class="lunbo" alt="lunbo"/>
                </div>
            </Carousel>
                <div className="description">
                    <div className="bgtitle">
                        <img src={about} alt="about"/>
                    </div>
                    <div className="aboutcount">
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;“百姓文化云”是由河南省委宣传部、省文联、省文化和旅游厅、省广播电视局、省体育局牵头组织，
                        中原出版传媒集团负责建设运营的河南省数字公共文化服务平台。“百姓文化云”自2018年上线以来，已汇集全省公共文化资源和社会文化资源，为全省百姓提供丰富的文化场馆服务、文化活动服务、文化消费服务和文化内容服务。</div>
                    </div>
                </div>
                <div className="develop">
                    <div className="bgtitle">
                        <img src={develop} alt="bgtitle"/>
                    </div>
                    <div className="aboutcount" >
                        <div className="fzlc">

                            <div className="developcion">
                                <img className="fzlcs" src={this.state.develop1} onMouseOver={() => this.dianji("1")}
                                     onMouseOut={() => this.mouseout(1)} alt={this.state.develop1}/>
                                <img className="fzlcs" src={this.state.develop2} onMouseOver={() => this.dianji("2")}
                                     onMouseOut={() => this.mouseout(2)} alt={this.state.develop2}/>
                                <img className="fzlcs" src={this.state.develop3} onMouseOver={() => this.dianji("3")}
                                     onMouseOut={() => this.mouseout(3)} alt={this.state.develop3}/>
                                <img className="fzlcs" src={this.state.develop4} onMouseOver={() => this.dianji("4")}
                                     onMouseOut={() => this.mouseout(4)} alt={this.state.develop4}/>
                            </div>
                           <div className="developcion2">
                                <img className="fzlcs2" src={this.state.develop5} onMouseOver={() => this.dianji("5")}
                                     onMouseOut={() => this.mouseout(5)} alt={this.state.develop5}/>
                                <img className="fzlcs2" src={this.state.develop6} onMouseOver={() => this.dianji("6")}
                                     onMouseOut={() => this.mouseout(6)} alt={this.state.develop6}/>
                                <img className="fzlcs2" src={this.state.develop7} onMouseOver={() => this.dianji("7")}
                                     onMouseOut={() => this.mouseout(7)} alt={this.state.develop7}/>
                                <img className="fzlcs2" src={this.state.develop8} onMouseOver={() => this.dianji("8")}
                                     onMouseOut={() => this.mouseout(8)} alt={this.state.develop8}/>
                                <img className="fzlcs2 lastcoin" src={this.state.develop9} alt={this.state.develop9}
                                     onMouseOver={() => this.dianji("9")} onMouseOut={() => this.mouseout(9)}/>
                            </div>
                            <img className="flcimg" src={fzlcimg} alt={fzlcimg}/>
                        </div>

                    </div>
                </div>
                <div className="contact">
                    <div className="bgtitle">
                        <img src={contact} alt={contact}/>
                    </div>
                    <div className="aboutcount">
                        <div class="lxwm">
                            <img src={lxwm} alt={lxwm}/>
                        </div>
                    </div>
                </div>
                <div class="footertop">
                    <img style={{width:"100%"}} src={bootom} alt={bootom}/>
                </div>
                <div className="bootomss">
                    <div className="feet">
                        <p>联系电话：4008809382 |<a href="/#">服务条款</a> | <a href="/#"
                                                                               onClick={this.feedBack()}> 意见反馈</a>
                        </p>
                        <p>
                            <a href="https://www.hnbxwhy.com/#/Xkz" target="_blank"> 增值电信业务经营许可证：豫B2-20130011
                                | </a>
                            <a href="http://video.hnbxwhy.com/9222976/wlwhjyxkz.png"
                               target="_blank">网络文化经营许可证：豫网文[2024]1532-018号</a>
                        </p>
                        <p>
                            <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">ICP备案号：豫B2-20130011-4
                                Copyright &copy; 2014-2024
                                www.hnbxwhy.com 河南百姓文化云版权所有，未经授权禁止复制或镜像<img src={beian}
                                                                                                  alt="百姓文化云备案"/></a>
                        </p>
                        {/*<p>*/}
                        {/*    <a href="'http://video.hnbxwhy.com/9222976/wlwhjyxkz.png'"> 网络文化经营许可证：豫网文[2024]1532-018号</a></p>*/}
                        {/*<p>*/}
                        {/*    <a href="https://www.iyunshu.com/marketing.html?pageId=2307050005718383">*/}
                        {/*        <p className="beianwz" style={{padding_left: '26%', float: 'left'}}>*/}
                        {/*            增值电信业务经营许可证：豫网文[2024]1532-018号豫B2-20130011-4 | Copyright &copy; 2014-2024 www.hnbxwhy.com*/}
                        {/*            文化云*/}
                        {/*            版权所有，未经授权禁止复制或镜像*/}
                        {/*        </p></a>*/}
                        {/*    <a href="https://beian.miit.gov.cn/#/Integrated/index"> <img src={beian}*/}
                        {/*                                                                 alt="百姓文化云备案"/>*/}
                        {/*    </a>*/}
                        {/*</p>*/}
                    </div>


                </div>
            </div>
        )
    }

}

export default About;
