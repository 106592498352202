import React from 'react';
import '../../../assets/whypc/css/index.css';
//import {Redirect}from 'react-router-dom';
//import Api from '../../model/Api';
//import Axios from 'axios'
import { Layout, Menu, Breadcrumb,Carousel,input } from 'antd';
//import ptitle from "../../../assets/images/why191108_login_title.png";
import Logo from "../../../assets/whypc/image/logo.png";
import  backmp4 from "../../../assets/whypc/image/backmp4.mp4";
import  video from "../../../assets/whypc/image/news1.mp4";
import  video2 from "../../../assets/whypc/image/news2.mp4";
import  video3 from "../../../assets/whypc/image/news3.mp4"
import  video4 from "../../../assets/whypc/image/news4.mp4"
import  video5 from "../../../assets/whypc/image/news5.mp4"
import  homeimg from "../../../assets/whypc/image/home.png";
import  zhibo from "../../../assets/whypc/image/zhibo.png";
import  zmdss from "../../../assets/whypc/image/zmdss.png";
import  huodongimg from "../../../assets/whypc/image/huodong.png";
import  my from "../../../assets/whypc/image/my.png";
import  homeimgafter from "../../../assets/whypc/image/homeafter.png";
import  zhiboafter from "../../../assets/whypc/image/zhiboafter.png";
import  zmdssafter from "../../../assets/whypc/image/zmdssafter.png";
import  huodongimgafter from "../../../assets/whypc/image/huodongafter.png";
import  myafter from "../../../assets/whypc/image/myafter.png";
import logoimg from "../../../assets/whypc/image/logoimg.png";
import android from "../../../assets/whypc/image/android.png";
import iosimg from "../../../assets/whypc/image/ios.png";
import xiaochengxu from "../../../assets/whypc/image/xiaochengxu.png";
import beian from "../../../assets/whypc/image/beian.png";
import {Link} from "react-router-dom";

const { Header, Content,Footer } = Layout;

class Index extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            vcount:5,
            abc:'0',
            appmenu1:"none",
            appmenu11:"block",
            appmenu2:"block",
            appmenu21:"none",
            appmenu3:"block",
            appmenu31:"none",
            appmenu4:"block",
            appmenu41:"none",
            appmenu5:"block",
            appmenu51:"none",
            videosrc:video,
            li:0,
            vsrc:{
                1:video,
                2:video2,
                3:video3,
                4:video4,
                5:video5
            }
        };
    }
    componentDidMount(){
        localStorage.setItem("newplayer",0);
        localStorage.setItem("seletKey",1);

        this.timer=setInterval( ()=>{
            this.formp4();
        }, 2000);
    }
    componentWillUnmount() {

        clearInterval(this.timer);
    }
    //视频自动播放
    formp4=()=>{
        let x = document.getElementById("myVideo").ended;
        if(x===true){
            let s=this.state.vcount;
            if(this.state.li>s-1){
                this.handleChange("li",1);//判断下一个视频是否大于视频列表数，如果大于就回0从列表第一个开始播放，由于数组默认是从0开始排序所以判断的时候要-1
            }else{
                this.handleChange("li",this.state.li+1)
            }
            this.handleChange("videosrc",this.state.vsrc[this.state.li]);//判断当前播放的视频已播放结束时将li加+，播放下一个视频
            let cname="appmenu"+this.state.li;
            for(let i=1;i<=this.state.vcount;i++){
                if(i===parseInt(this.state.li)){
                    this.handleChange(cname,"none");
                    this.handleChange(cname+1,"block");
                }else{
                    let cname2="appmenu"+i;
                    this.handleChange(cname2,"block");
                    this.handleChange(cname2+1,"none");
                }
            }
        }
    }
    //获取视频长度
    videolength =(videoId)=>{
        let timers;
        let video=document.getElementById(videoId);
        let that=this;
        video.addEventListener("canplay",function() {
            timers = Math.ceil(this.duration); //视频总时长
            localStorage.setItem(videoId,timers)
        })
    }
    //点击更换视频
    chengvideo=(v)=>{
        let newsrc=this.state.vsrc[v];
        this.handleChange("videosrc",newsrc);
        let cname="appmenu"+v;
        for(let i=1;i<=this.state.vcount;i++){
            if(i===parseInt(v)){
                this.handleChange(cname,"none");
                this.handleChange(cname+1,"block");
            }else{
                let cname2="appmenu"+i;
                this.handleChange(cname2,"block");
                this.handleChange(cname2+1,"none");
            }
        }
    }
    //修改state
    handleChange=(name,val)=> {
        this.setState({
            [name]: val
        })
    }

    render(){
        const { current } = this.state;
        return(
            <div className="content">
            <div className="middle">

                <video className="video" muted="muted" src={backmp4} type="video/mp4" loop="loop" preload="auto"
                       autoPlay="autoplay">
                </video>
                <div className="zhong">
                    <div className="middlecart">
                    <div className="phone" id="phone" >

                        <div className="phoneimg"></div>

                        <div className="dvideo">
                            <video className="myvideo" id="myVideo" muted="muted" style={{display:this.state.video}} src={this.state.videosrc} alt={this.state.videosrc} type="video/mp4" autoPlay="autoplay">
                            </video>
                        </div>
                        <div>
                            <div className="appbootm">
                                <div className="appmenu">
                                    <div className="appmenu1" id="appmenu1" style={{display:this.state.appmenu1}} onClick={()=>this.chengvideo("1")}></div>
                                    <div className="appmenu11" id="appmenu11" style={{display:this.state.appmenu11}}></div>
                                </div>
                                <div className="appmenu">
                                    <div className="appmenu2" id="appmenu2"  style={{display:this.state.appmenu2}} onClick={()=>this.chengvideo("2")}></div>
                                    <div className="appmenu21" id="appmenu21"  style={{display:this.state.appmenu21}}></div>
                                </div>
                                <div className="appmenu">
                                    <div className="appmenu3" id="appmenu3" style={{display:this.state.appmenu3}} onClick={()=>this.chengvideo("3")}></div>
                                    <div className="appmenu31" id="appmenu31"style={{display:this.state.appmenu31}} ></div>
                                </div>
                                <div className="appmenu">
                                    <div className="appmenu4" id="appmenu4" style={{display:this.state.appmenu4}} onClick={()=>this.chengvideo("4")}></div>
                                    <div className="appmenu41" id="appmenu41" style={{display:this.state.appmenu41}} ></div>
                                </div>
                                <div className="appmenu">
                                    <div className="appmenu5" id="appmenu5" style={{display:this.state.appmenu5}} onClick={()=>this.chengvideo("5")}></div>
                                    <div className="appmenu51" id="appmenu51" style={{display:this.state.appmenu51}} ></div>
                                </div>

                            </div>
                        </div>

                    </div>
                    {/*<div className="guangshu" id="guangshu" style={{opacity:this.state.abc}}>*/}
                    {/*</div>*/}
                    <div className="rightcontent">
                        <div className="righttitle">
                            <img src={logoimg} alt={logoimg}/>
                        </div>
                        <div className="qrcode">
                            <div className="androidmap">
                                <div className="androidss"></div>
                                <div className="codeimg">
                                    <img src={android} alt={android}/>
                                </div>
                            </div>
                            <div className="iosmap">
                                <div className="ios"></div>
                                <div className="ioscodeimg">
                                    <img src={iosimg} alt={iosimg}/>
                                </div>
                            </div>
                            <div className="xcxmap">
                                <div className="xcx"></div>
                                <div className="xcxcodeimg">
                                    <img src={xiaochengxu} alt={xiaochengxu}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    {/*底部*/}
                    <div className="footer">
                        <div className="foottop">
                            <div className="yqlj">
                                <ul className="list">
                                    <li><a href="/#">友情链接</a></li>
                                    <li><a href="/#">移动文化云</a></li>
                                    <li><a href="/#">商务合作</a></li>
                                    <li><a href="https://www.hnbxwhy.com/#/about">关于我们</a></li>
                                </ul>
                            </div>
                        </div>
                        <Footer className="bootom">
                            <div className="feet">
                                <p>联系电话：4008809382 |< a href="/#">服务条款</ a> | < a
                                    href="/#" onClick="feedBack()"> 意见反馈</ a></p>
                                <p>
                                    <a href="https://www.hnbxwhy.com/#/Xkz" target = "_blank"> 增值电信业务经营许可证：豫B2-20130011 | </a>
                                    <a href="http://video.hnbxwhy.com/9222976/wlwhjyxkz.png" target="_blank">网络文化经营许可证：豫网文[2024]1532-018号</a>
                                </p>
                                <p>
                                    <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">ICP备案号：豫B2-20130011-4 Copyright &copy; 2014-2024
                                        www.hnbxwhy.com 河南百姓文化云版权所有，未经授权禁止复制或镜像<img src={beian} alt="百姓文化云备案"/></a>
                                </p>
                                {/*<p>*/}
                                {/*    <a href="http://video.hnbxwhy.com/9222976/wlwhjyxkz.png"> 网络文化经营许可证：豫网文[2024]1532-018号</a>*/}
                                {/*    <a href="https://www.iyunshu.com/marketing.html?pageId=2307050005718383">*/}
                                {/*        <p style={{float: "left"}}>增值电信业务经营许可证：豫B2-20130011 |*/}
                                {/*            Copyright &copy; 2014-2024 www.hnbxwhy.com 文化云*/}
                                {/*            版权所有，未经授权禁止复制或镜像</p></a>*/}
                                {/*      <a href="https://beian.miit.gov.cn/#/Integrated/index">  <img src={beian} alt="百姓文化云备案"*/}
                                {/*             style={{padding_left: '5px', padding_top: '4px'}}/></a>*/}

                                {/*</p>*/}
                            </div>

                        </Footer>
                    </div>

                </div>

            </div>
            </div>
        )
    }
}

export default Index;
